"use client";

import { Box, Typography } from "@mui/material";
import Link from "next/link";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography fontWeight="bold" variant="h4">
        We are sorry
      </Typography>
      <p>Something went wrong</p>
      <Link href="/">Return Home</Link>
    </Box>
  );
}
